import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { device } from './layout/GlobalStyles';
import { ReactComponent as BlobThree } from '../images/svgs/blob-3.svg';
import { ReactComponent as BlobFour } from '../images/svgs/blob-4.svg';
import { ReactComponent as BlobFive } from '../images/svgs/blob-5.svg';

const Container = styled.div`
    margin-top: 72px;
    h2 {
        text-align: center;
        margin-bottom: 72px;
    }
    @media ${device.tablet} {
        margin-top: 172px;
        h2 {
            margin-bottom: 142px;
        }
    }
    @media ${device.laptop} {
        h2 {
            margin-bottom: 72px;
        }
    }
`;

const BlobContainer = styled.div`
    @media ${device.laptop} {
        display: flex;
        justify-content: space-between;
        max-width: 1180px;
        margin: 0 auto;
    }
    @media ${device.laptopL} {
        display: flex;
        justify-content: space-between;
        width: 1300px;
    }
`;

const Blob = styled.div`
    height: 207px;
    margin-bottom: 122px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .blob-three {
        opacity: 0.8;
    }
    svg {
        opacity: 0.5;
    }
    div {
        position: absolute;
        min-width: 80px;
        height: 80px;
        bottom: 170px;
        img {
            min-width: 80px;
            height: 80px;
        }
    }
    svg {
        width: 280.69px;
    }
    p:first-of-type {
        font-weight: bold;
        margin-bottom: 0;
        /* margin-top: 172px; */
    }
    p:last-of-type {
        padding: 0px 16px;
    }
    p {
        text-align: center;
    }
    svg:first-of-type {
        z-index: -1;
        position: absolute;
    }
    @media ${device.tablet} {
        margin-bottom: 178px;
        svg {
            width: 400.69px;
        }
        p:last-of-type {
            max-width: 400px;
        }
    }
    @media ${device.laptop} {
        height: 227px;
        margin-bottom: 128px;

        div {
            height: 80px;
            position: absolute;
            min-width: 80px;
            height: 80px;
            bottom: 00px;
            img {
                height: 80px;
            }
        }
        svg {
            width: 300.69px;
        }
        p:first-of-type {
            margin-left: 15px;
        }
        p {
            text-align: left;
            align-self: start;
        }
        div {
            position: relative;
            right: 90px;
            height: 100%;
            img {
            }
        }
    }
    @media ${device.laptopL} {
        height: 257px;
        div {
            min-width: 80px;
            height: 80px;
            bottom: 00px;
            left: -120px;
            img {
                height: 80px;
            }
        }
    }
`;

export const WhatMakesUsBold = () => {
    return (
        <Container className="container">
            <h2>
                What makes us{' '}
                <span>
                    bold<div></div>
                </span>
            </h2>

            <BlobContainer>
                <Blob>
                    <StaticImage
                        src="../images/hearts.png"
                        quality={100}
                        alt="hearts"
                        placeholder="none"
                    />
                    <p style={{ color: `#EE596B` }}>Beloved by Patients</p>
                    <p>
                        Dr. T. and his staff adore the kids, and it shows! Thanks for all the praise
                        from our patients.
                    </p>
                    <BlobThree className="blob-three" />
                </Blob>
                <Blob>
                    <StaticImage
                        src="../images/clock.png"
                        quality={100}
                        alt="hearts"
                        placeholder="none"
                    />
                    <p style={{ color: `#26A312` }}>On Time Appointments</p>
                    <p>Call us to schedule your appointment</p>
                    <BlobFour />
                </Blob>
                <Blob>
                    <StaticImage
                        src="../images/forms.png"
                        quality={100}
                        alt="hearts"
                        placeholder="none"
                    />
                    <p style={{ color: `#0C9372` }}>Online Forms</p>
                    <p>
                        For the convenience of our patients, we offer numerous of our forms online,
                        to make the visit more pleasant.
                    </p>
                    <BlobFive />
                </Blob>
            </BlobContainer>
        </Container>
    );
};
