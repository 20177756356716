import React from 'react';
import { HomeBanner } from '../components/Home-Banner';
import { MeetDrFarazHome } from '../components/Meet-Dr.Daraz-Home';
import { NoInsurance } from '../components/No-Insurance';
import { OurServices } from '../components/Our-Services';
import { PatientReviews } from '../components/Patient-Reviews';
import { WhatMakesUsBold } from '../components/What-Makes-Us-Bold';
import { WhatToExpect } from '../components/What-To-Expect';
import { WhoWeAre } from '../components/Who-We-Are';
import { Seo } from '../components/Seo';

const Home: Page = () => (
    <>
        <Seo
            title="Clermont Kids Dentistry, P.A. | Pediatric Dentist - Clermont, FL"
            description={`Dr. T. and his team are the only 5 star rated pediatric dentist in Clermont, Leesburg, Winter Gardens, Ocoee and the surrounding area. We know that dental health is important to your child's overall health and appearance and every child is different in their own way.`}
        />
        <main>
            <HomeBanner />
            <WhoWeAre />
            <WhatMakesUsBold />
            <OurServices />
            <MeetDrFarazHome />
            <WhatToExpect />
            <PatientReviews />
            <NoInsurance />
        </main>
    </>
);

export default Home;
