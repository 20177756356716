import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { device } from './layout/GlobalStyles';
import { SecondaryButton } from './layout/StyledComponents';
import { Link } from 'gatsby';
import background from '../images/gray-background.webp';

const StyledWhatToExpect = styled.div`
    margin-top: 128px;
    background-image: url(${background});
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    padding: 64px 0px 64px;
    /* width: 100vw; */
    /* overflow: hidden; */
    h2,
    p {
        color: white;
    }
    @media ${device.laptop} {
        padding: 110px 0px 128px;
    }
`;

const FirstContent = styled.div`
    padding-top: 0px;
    @media ${device.laptop} {
        display: flex;

        justify-content: space-between;
        p {
            width: 500px;
        }
    }
    @media ${device.desktop} {
        h2 {
            padding-right: 32px;
        }
    }
`;

const Image = styled.div`
    position: relative;

    height: 500px;
    .first-visit-image {
        border-radius: 15px;
        img {
            margin-top: 32px;
            border-radius: 15px;
        }
    }
    .first-visit-image + div {
        right: -20px;
        top: 340px;
        width: 190px;
        z-index: 2;
        position: absolute;
    }
    @media ${device.tablet} {
        .first-visit-image {
            border-radius: 15px;
            img {
                min-width: 414px;
                height: 515px;
                margin-top: 32px;
                border-radius: 15px;
            }
        }
        .first-visit-image + div {
            right: 30px;
            top: 340px;
            width: 190px;
            z-index: 2;
            position: absolute;
        }
    }
    @media ${device.laptop} {
        .first-visit-image {
            border-radius: 15px;
            img {
                min-width: 414px;
                height: 515px;
                margin-top: 32px;
                border-radius: 15px;
            }
        }
        .first-visit-image + div {
            right: -40px;
            top: 370px;
            width: 236px;
            z-index: 2;
            position: absolute;
        }
    }
`;

const SecondaryContent = styled.div`
    margin-top: 72px;
    p {
        padding: 0px 16px;
    }
    .scroll-container {
        display: flex;
        overflow-x: scroll;
        flex-wrap: nowrap;
        padding: 0px 16px;
        div {
            margin: 0px 8px;
            height: 190px;
            min-width: 250px;
            img {
                min-width: 250px;
                height: 190px;
                border-radius: 15px;
            }
        }
    }
    @media ${device.tablet} {
        margin-top: 128px;

        p {
            text-align: center;
            max-width: 600px;
            margin: 0 auto 36px;
        }
        .scroll-container {
            display: flex;

            overflow-x: hidden;
        }
    }
    @media ${device.laptop} {
        display: flex;
        flex-direction: column;
        align-items: center;
        .scroll-container {
            div {
                margin: 0px 8px;
                height: 250px;
                min-width: 320px;
                img {
                    min-width: 320px;
                    height: 250px;
                    border-radius: 15px;
                }
            }
        }
        @media ${device.desktop} {
            .scroll-container {
                div {
                    margin: 0px 8px;
                    height: 300px;
                    min-width: 380px;
                    img {
                        min-width: 380px;
                        height: 300px;
                        border-radius: 15px;
                    }
                }
            }
        }
    }
    @media ${device.desktop} {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const WhatToExpect = () => {
    return (
        <StyledWhatToExpect>
            {/* <BG>
                <StaticImage
                    src="../images/grey-background.jpeg"
                    alt="gray background"
                    quality={100}
                    placeholder="none"
                />
            </BG> */}
            <FirstContent className="container">
                <div>
                    <h2 style={{ color: 'white' }}>
                        What to Expect on{' '}
                        <span>
                            {' '}
                            your First Visit<div></div>
                        </span>
                    </h2>
                    <p>
                        The first dental visit is usually short and involves very little treatment.
                        This visit gives your child an opportunity to meet the dentist in a
                        non-threatening and friendly way. Some dentists may ask the parent to sit in
                        the dental chair and hold their child during the exam.
                    </p>
                    <Link to="/for-patients/your-first-visit/">
                        <SecondaryButton color="yellow">Get More information</SecondaryButton>
                    </Link>
                </div>
                <Image>
                    <StaticImage
                        className="first-visit-image"
                        src="../images/first-visit.jpeg"
                        alt="Dr. with children"
                        quality={100}
                        placeholder="none"
                    />
                    <StaticImage
                        src="../images/giraffe.png"
                        alt="toy giraffe"
                        quality={100}
                        placeholder="none"
                    />
                </Image>
            </FirstContent>
            <SecondaryContent>
                <p>
                    Our office makes sure to use the latest procedures and equipment to make their
                    visit as pleasant as possible.
                </p>
                <div className="scroll-container">
                    <StaticImage
                        src="../images/homepage-first-visit-2.jpg"
                        alt="doctor with patient"
                        quality={100}
                        placeholder="none"
                    />
                    <StaticImage
                        src="../images/homepage-first-visit-3.jpg"
                        alt="office space"
                        quality={100}
                        placeholder="none"
                    />
                    <StaticImage
                        src="../images/homepage-first-visit-4.jpg"
                        alt={`Dr. Faraz treating child's teeth`}
                        quality={100}
                        placeholder="none"
                    />
                </div>
            </SecondaryContent>
        </StyledWhatToExpect>
    );
};
