import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { ReactComponent as Marker } from '../images/svgs/marker.svg';
import { ReactComponent as Mobile } from '../images/svgs/mobile-white.svg';
import { ButtonPrimary, SecondaryButton } from './layout/StyledComponents';
import { ReactComponent as Wave } from '../images/svgs/wave.svg';
import { ReactComponent as Girl } from '../images/svgs/girl.svg';
import { ReactComponent as Boy } from '../images/svgs/boy.svg';
import { ReactComponent as Teddy } from '../images/svgs/teddy.svg';
import { contact } from '../contact';

const StyledHomeBanner = styled.div`
    position: relative;
    height: 569px;

    .girl,
    .boy,
    .teddy {
        display: none;
    }
    .marker {
        width: 14px;
        margin-right: 15px;
        stroke: ${colors.gray5};
        fill: ${colors.gray5};
    }
    button {
        .desktop-mobile {
            transition: 0.2s ease-in;
            fill: white;
        }
        &:hover {
            .desktop-mobile {
                fill: ${colors.yellow3};
            }
        }
    }

    .location {
        display: flex;
        align-items: center;
        color: ${colors.gray5};
        width: 335px;
        p {
            width: 305px;
            font-size: 16px;
            text-decoration: underline;
        }
    }
    .first-button {
        margin-bottom: 16px;
    }
    .title-container {
        .sub-title {
            span {
                color: ${colors.yellow2};
            }
            letter-spacing: 0.1em;
            font-size: 32px;
            line-height: 40px;
        }
    }

    @media ${device.tablet} {
        height: 100%;

        .sub-title {
            padding-top: 40px;
            font-size: 42px;
            line-height: 54px;
        }
        a + p {
            width: 450px;
        }
    }
    @media ${device.laptop} {
        .title-container {
            .sub-title {
                font-size: 48px;
                line-height: 58px;
            }
        }
        .location {
            display: flex;
            align-items: center;
            color: ${colors.gray5};
            width: 335px;
            svg {
                fill: white;
                stroke: white;
            }
            p {
                color: white;
                width: 305px;
                font-size: 16px;
                text-decoration: underline;
            }
        }
        a + p {
            width: 450px;
            color: white;
        }
        .girl,
        .boy,
        .teddy {
            display: block;
        }
        .title-container {
            background: rgba(89, 81, 81, 0.36);
            backdrop-filter: blur(21px);
            border-radius: 15px;
            width: 715px;
            margin-top: -50px;
            z-index: 0;

            margin-right: 150px;
            padding: 00px 70px 70px 70px;
            position: relative;
            .girl,
            .boy,
            .teddy {
                position: absolute;
            }
            .teddy {
                right: -50px;
                top: -50px;
            }
            .girl {
                width: 149px;

                right: -50px;
                bottom: -50px;
            }
            .boy {
                width: 136px;

                left: -50px;
                z-index: 2;
                bottom: -50px;
            }
            h2 {
                color: white;
                width: 603px;
                margin-bottom: 00px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 700;
                font-size: 56px;
                line-height: 84px;
            }
        }
        .button-container {
            display: flex;
            margin-top: 32px;
            align-items: center;
            button:first-of-type {
                color: red;
                margin-right: 20px;
            }
            button:last-of-type {
                margin-bottom: 15px;
            }
        }
    }
    @media ${device.laptop} {
        .button-container {
            button:last-of-type {
                margin-bottom: 15px;
                color: white;
                div {
                    border: 2px solid white;
                }
                svg {
                    stroke: white;
                    fill: white;
                }
            }
        }
    }
    @media ${device.laptopL} {
        .title-container {
            top: 20px;
        }
    }
    @media ${device.desktop} {
        .title-container {
            top: 40px;
        }
    }
`;

const BGImage = styled.div`
    position: absolute;
    bottom: -10vh;
    z-index: -1;
    .hero-image {
        z-index: -2;
        width: 100vw;
    }
    svg {
        width: 100vw;
        position: absolute;
        bottom: -10px;
    }
    @media ${device.tablet} {
        bottom: -5vh;
    }
    @media ${device.laptopL} {
        position: absolute;
        bottom: -20vh;
        z-index: -1;
        svg {
            bottom: 0vh;
        }
    }
    @media ${device.desktop} {
        position: absolute;
        bottom: -30vh;
        z-index: -1;
        svg {
            bottom: 0vh;
        }
    }
`;

export const HomeBanner: React.FC = () => {
    return (
        <StyledHomeBanner>
            <BGImage>
                <Wave />
                <StaticImage
                    loading="eager"
                    className="hero-image"
                    src="../images/home-hero.jpeg"
                    quality={100}
                    alt="children smiling"
                />
            </BGImage>
            <div className="container">
                <div className="title-container">
                    <Girl className="girl" />
                    <Boy className="boy" />
                    <Teddy className="teddy" />
                    <h2 className="sub-title">
                        <span>Dentist in Clermont, FL</span>
                        <br />
                        It’s Never Too Early To Get Started
                    </h2>
                    <a
                        target="__blank"
                        rel="noopener noreferrer"
                        href="https://www.google.com/maps/place/Clermont+Kids+Dentistry/@28.5451299,-81.7281532,17z/data=!3m2!4b1!5s0x88e78f1ae354c4cf:0x58a0adfd23d094ef!4m5!3m4!1s0x88e78fa972003fe3:0x659ad2489513a941!8m2!3d28.5451299!4d-81.7259645"
                        className="location"
                    >
                        <Marker className="marker" />
                        <p>1655 E. Hwy 50 Suite 101, Clermont, Florida 34711</p>
                    </a>
                    <p>
                        Dr. T cares for the individual needs of all his patients. He never separates
                        the child from a parent.
                    </p>
                    <div className="button-container">
                        <a href={`tel:${contact.phone}`} rel="noopener noreferrer" target="_blank">
                            <ButtonPrimary className="first-button" fill>
                                <Mobile className="desktop-mobile" />
                                Call us - (407) 205-1114
                            </ButtonPrimary>
                        </a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://patientportal-cs4.carestack.com/?dn=ck&ln=1/#/online-appointments"
                        >
                            <SecondaryButton className="make-appt-button" color="blue">
                                Make Appointment
                            </SecondaryButton>
                        </a>
                    </div>
                </div>
            </div>
        </StyledHomeBanner>
    );
};
