import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { device } from './layout/GlobalStyles';
import { SecondaryButton } from './layout/StyledComponents';
import { Link } from 'gatsby';

const MeetDrContainer = styled.div`
    padding: 72px 16px;
    .image {
        img {
            border-radius: 15px;
        }
    }

    @media ${device.laptop} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .image {
            height: 431px;
            img {
                height: 431px;
                border-radius: 15px;
            }
        }
    }
    @media ${device.laptopL} {
        .image {
            height: 531px;
            img {
                height: 531px;
                border-radius: 15px;
            }
        }
    }
`;

const Text = styled.div`
    @media ${device.laptop} {
        max-width: 522px;
        display: flex;
        flex-direction: column;
        button {
            align-self: end;
        }
    }
`;

const SecondImage = styled.div`
    margin-bottom: -39px;
    img {
        border-radius: 15px;
    }
    @media ${device.laptop} {
        margin-bottom: 0px;
    }
`;

export const MeetDrFarazHome = () => {
    return (
        <>
            <MeetDrContainer className="container">
                <StaticImage
                    className="image"
                    src="../images/dr-faraz.jpeg"
                    alt="dr. faraz smiling"
                    placeholder="none"
                    quality={100}
                />
                <Text>
                    <h2>
                        Meet Dr.{' '}
                        <span>
                            Faraz tartibi<div></div>
                        </span>
                    </h2>

                    <p>
                        <span>Dr. F. Tartibi (Dr. T.)</span> is from Oviedo Florida. He has always
                        imagined growing his family in central Florida. He and his wife, Patricia,
                        are proud parents of the furriest Pomeranian named Rambo. As a family, the
                        Tartibi’s work hard and play hard with goals to improve daily no matter the
                        circumstance. They believe children’s imaginations exude pure originality
                        and creativity which allows children to experience an adventurous life!
                        Having fun is often at the top of their priority list!
                    </p>
                    <Link to="/about-us/dr-farad-tartibi/">
                        <SecondaryButton color="blue">Know more about Dr. Tartibi</SecondaryButton>
                    </Link>
                </Text>
            </MeetDrContainer>
            <SecondImage className="container">
                <StaticImage
                    className="second-image"
                    src="../images/dr-and-kids.jpeg"
                    alt="dr. faraz smiling with children"
                    placeholder="none"
                    quality={100}
                />
            </SecondImage>
        </>
    );
};
