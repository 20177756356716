import React, { useState } from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { device } from './layout/GlobalStyles';
import { SecondaryButton } from './layout/StyledComponents';
import { Link } from 'gatsby';

const Container = styled.div`
    padding: 0px 0px 72px;
    h2 {
        text-align: center;
    }
    @media ${device.laptop} {
        h2 {
            text-align: left;
        }
        display: flex;
        padding: 0px 0px 72px;
    }
`;

const BG = styled.div`
    position: absolute;
    z-index: -1;
    div {
        height: 670px;
    }
    @media ${device.tablet} {
        div {
            height: 690px;
        }
    }
    @media ${device.laptop} {
        div {
            width: 100vw;
            height: 100%;
        }
    }
`;

const OutterTitleContainer = styled.div`
    padding-top: 64px;
    button {
        margin: 0 auto;
    }
    @media ${device.laptop} {
        padding-left: 100px;
        button {
            position: relative;
            right: 16px;
        }
    }
`;

const CardContainer = styled.div`
    overflow-x: scroll;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 72px;
    padding: 0px 32px;
    > div {
        margin: 0 8px;
    }
    @media ${device.laptop} {
        margin-top: 0px;
        position: relative;
        top: 78px;
        overflow-y: hidden;
        overflow-x: hidden;
        min-height: 800px;
        max-width: 1200px;
        padding: 0px 32px;
    }
    @media ${device.laptopL} {
        min-height: 900px;
    }
    @media ${device.desktop} {
        margin-top: 128px;
        top: 0px;

        height: 900px;
        max-width: 1200px;
        padding: 0px 32px;
    }
`;

interface Props {
    index: number;
    number: number;
}
const Card = styled.div<Props>`
    height: 341px;
    min-width: 278px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;

    p {
        color: white;
    }
    p:first-of-type {
        font-weight: bold;
        font-size: 20px;
        position: relative;
        top: 22px;
        line-height: 144%;
    }
    p:last-of-type {
        display: none;
    }
    p,
    button {
        padding-left: 16px;
    }
    button {
        padding-bottom: 32px;
        position: relative;
        top: 16px;
    }
    .image-container {
        border-radius: 15px;
        height: 341px;
        width: 100%;
        position: relative;
        z-index: -1;

        .shadow + div {
            border-radius: 15px;
            width: 100%;
            height: 341px;
            width: 278px;
            position: absolute;
            img {
                width: 278px;
                height: 341px;
                filter: brightness(90%);
                border-radius: 15px;
            }
        }
    }

    .shadow {
        position: absolute;
        height: 100%;
        bottom: -140px;
        z-index: 1;
        width: 100%;
        border-radius: 15px;
        background: linear-gradient(
                360deg,
                #030303 0%,
                rgba(3, 3, 3, 0.80136) 32.88%,
                rgba(0, 0, 0, 0) 55.52%
            ),
            url(image.png);
    }
    @media ${device.tablet} {
        .image-container {
            .shadow + div {
                border-radius: 15px;
                width: 100%;
                height: 341px;
                position: absolute;
                img {
                    filter: brightness(100%);
                    border-radius: 15px;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    @media ${device.laptop} {
        transition: min-width 0.2s ease-in;
        min-width: ${({ index, number }) => (index === number ? '40%' : '15%')};
        height: ${({ index, number }) => (index === number ? '671px' : '559px')};
        button {
            position: relative;
            bottom: ${({ index, number }) => (index === number ? '25px' : '0')};
        }
        p:last-of-type {
            position: relative;
            bottom: ${({ index, number }) => (index === number ? '50px' : '0')};
            transition: opacity 0.5s ease-in;
            display: block;
            padding: 0px 40px 0px 20px;
            height: ${({ index, number }) => (index === number ? '120px' : '0')};
            opacity: ${({ index, number }) => (index === number ? '1' : '0')};
        }
        .shadow {
            background: linear-gradient(
                360deg,
                #030303 0%,
                rgba(3, 3, 3, 0.80136) 44.46%,
                rgba(0, 0, 0, 0) 75.07%
            );
            position: absolute;
            height: 671px;
            transition: 0.2s ease-in;
            width: ${({ index, number }) => (index === number ? '100%' : '100%')};

            bottom: -250px;
        }
        @keyframes slidein {
            0% {
                transform: translateX(0%);
            }

            50% {
                transform: translateX(120%);
            }
            75% {
                transform: translateX(90%);
            }
            100% {
                transform: translateX(100%);
            }
        }
        p:first-of-type {
            height: 120px;
            transition: 0.2s ease-in;
            transform: ${({ index, number }) => (index === number ? '' : 'rotate(90deg)')};
            width: ${({ index, number }) => (index === number ? '' : '160%')};
            margin-top: -60px;
            margin-left: ${({ index, number }) => (index === number ? '' : '-60px')};
            position: relative;
            top: ${({ index, number }) => (index === number ? '20px' : '120px')};
            right: ${({ index, number }) => (index === number ? '' : '40px')};
        }
        button {
            position: relative;
            top: 0px;
            transition: 0.5s ease-in;
            opacity: ${({ index, number }) => (index === number ? '1' : '0')};
        }
        .image-container {
            min-width: ${({ index, number }) => (index === number ? '40%' : '15%')};
            height: 651px;
            transition: 0.2s ease-in;

            .shadow + div {
                min-width: ${({ index, number }) => (index === number ? '40%' : '15%')};
                transition: 0.2s ease-in;
                height: 651px;
            }
        }
    }
    @media ${device.laptopL} {
        p:last-of-type {
            position: relative;
            bottom: ${({ index, number }) => (index === number ? '00px' : '0')};
        }
        p:first-of-type {
            top: ${({ index, number }) => (index === number ? '70px' : '160px')};
            right: ${({ index, number }) => (index === number ? '00px' : '60px')};
        }
    }
    @media ${device.desktop} {
        transition: min-width 0.2s ease-in;
        min-width: ${({ index, number }) => (index === number ? '40%' : '15%')};
        height: ${({ index, number }) => (index === number ? '671px' : '551px')};
        button {
            position: relative;
            bottom: ${({ index, number }) => (index === number ? '35px' : '0')};
        }
        p:last-of-type {
            transition: opacity 0.5s ease-in;
            display: block;
            position: relative;
            bottom: ${({ index, number }) => (index === number ? '20px' : '0')};
            padding: 0px 40px 0px 20px;
            height: ${({ index, number }) => (index === number ? '120px' : '0')};
            opacity: ${({ index, number }) => (index === number ? '1' : '0')};
        }
        .shadow {
            background: linear-gradient(
                360deg,
                #030303 0%,
                rgba(3, 3, 3, 0.80136) 44.46%,
                rgba(0, 0, 0, 0) 75.07%
            );
            position: absolute;
            height: 651px;
            transition: 0.2s ease-in;
            width: ${({ index, number }) => (index === number ? '100%' : '100%')};

            bottom: -240px;
        }
        @keyframes slidein {
            0% {
                transform: translateX(0%);
            }

            50% {
                transform: translateX(120%);
            }
            75% {
                transform: translateX(90%);
            }
            100% {
                transform: translateX(100%);
            }
        }
        button {
            margin-top: -10px;
            position: relative;
            left: 7px;
            transition: 0.5s ease-in;
            opacity: ${({ index, number }) => (index === number ? '1' : '0')};
        }
        .image-container {
            min-width: ${({ index, number }) => (index === number ? '40%' : '15%')};
            height: 651px;
            transition: 0.2s ease-in;
            .shadow + div {
                min-width: ${({ index, number }) => (index === number ? '40%' : '15%')};
                transition: 0.2s ease-in;
                height: 651px;
            }
        }
        p:first-of-type {
            font-weight: 600;
            font-size: 24px;
            line-height: 35px;
            height: 160px;
            transition: 0.2s ease-in;
            transform: ${({ index, number }) => (index === number ? '' : 'rotate(90deg)')};
            margin-top: -100px;
            margin-left: ${({ index, number }) => (index === number ? '' : '-120px')};
            position: relative;
            top: ${({ index, number }) => (index === number ? '80px' : '150px')};
            right: ${({ index, number }) => (index === number ? '' : '40px')};
        }
        p {
            position: relative;
            bottom: ${({ index, number }) => (index === number ? '50px' : '0')};
        }
    }
    @media ${device.desktopL} {
        p:first-of-type {
            top: ${({ index, number }) => (index === number ? '40px' : '150px')};
        }
        p:last-of-type {
            bottom: ${({ index, number }) => (index === number ? '60px' : '0')};
        }
    }
`;

export const OurServices = () => {
    const [index, setIndex] = useState(0);
    return (
        <Container>
            <BG>
                <StaticImage
                    className="bg"
                    src="../images/children-room.jpeg"
                    alt="children`s room"
                    placeholder="none"
                    quality={100}
                />
            </BG>
            <OutterTitleContainer>
                <h2>
                    our{' '}
                    <span>
                        Services<div></div>
                    </span>
                </h2>
                <Link to="/our-services">
                    <SecondaryButton color="blue">View More Services</SecondaryButton>
                </Link>
            </OutterTitleContainer>
            <CardContainer>
                <Card number={0} index={index} onMouseEnter={() => setIndex(0)}>
                    <div className="image-container">
                        <div className="shadow"></div>
                        <StaticImage
                            src="../images/service-card-1.jpg"
                            placeholder="none"
                            quality={100}
                            alt="child brushing teeth"
                        />
                    </div>
                    <p>Preventive Dentistry</p>
                    <p>
                        We focus on preventive care to help your child avoid oral issues and keep
                        smiling with carefree ease. Proper oral hygiene and preventive visits are
                        essential as your child grows, so book an appointment today!
                    </p>
                    <Link to="/our-services/preventive-dentistry/">
                        <SecondaryButton color="yellow">Explore more</SecondaryButton>
                    </Link>
                </Card>

                <Card number={1} index={index} onMouseEnter={() => setIndex(1)}>
                    <div className="image-container">
                        <div className="shadow"></div>
                        <StaticImage
                            src="../images/service-card-2.jpg"
                            placeholder="none"
                            quality={100}
                            alt="child smiling at dentist"
                        />
                    </div>
                    <p>Restorative Dentistry</p>
                    <p>
                        If your child’s tooth has been damaged because of cavities or trauma we are
                        here to help. Getting immediate treatment for oral issues is the best way to
                        get your little one relief from their pain and discomfort.
                    </p>
                    <Link to="/our-services/restorative-dentistry/">
                        <SecondaryButton color="yellow">Explore more</SecondaryButton>
                    </Link>
                </Card>
                <Card number={2} index={index} onMouseEnter={() => setIndex(2)}>
                    <div className="image-container">
                        <div className="shadow"></div>
                        <StaticImage
                            src="../images/service-card-3.jpg"
                            placeholder="none"
                            quality={100}
                            alt="child smiling"
                        />
                    </div>
                    <p>Lip/Tongue Tie</p>
                    <p>
                        The health of your child’s teeth and mouth is very important to the
                        well-being of his or her entire body, and while routine brushing and
                        flossing at home is necessary to keep your child’s smile looking its best.
                    </p>
                    <Link to="/our-services/lip-tongue-tie/">
                        <SecondaryButton color="yellow">Explore more</SecondaryButton>
                    </Link>
                </Card>
                <Card number={3} index={index} onMouseEnter={() => setIndex(3)}>
                    <div className="image-container">
                        <div className="shadow"></div>
                        <StaticImage
                            src="../images/service-card-4.jpg"
                            placeholder="none"
                            quality={100}
                            alt="child smiling with braces"
                        />
                    </div>
                    <p>Orthodontics</p>
                    <p>
                        We are proud to have been part of the community providing orthodontic
                        services including traditional braces and clear aligner orthodontic
                        treatment for our pediatric and adolescent patients.
                    </p>
                    <Link to="/our-services/orthodontics/">
                        <SecondaryButton color="yellow">Explore more</SecondaryButton>
                    </Link>
                </Card>
            </CardContainer>
        </Container>
    );
};
