/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { ReactComponent as Star } from '../images/svgs/star.svg';
import { ReactComponent as Blob } from '../images/svgs/blob-7.svg';
import { ReactComponent as LeftArrow } from '../images/svgs/left-arrow.svg';
import { ReactComponent as RightArrow } from '../images/svgs/right-arrow.svg';

const reviews = [
    {
        review: `The staff are so great and positive, and my son and I loved it!!!! They're very sweet and kind, definitely recommend this for your children's dentist.`,
        name: 'Ariana H.',
        rating: '4',
    },
    {
        review: `The office was clean and the staff were so friendly. My child loved her experience and cant wait to go back for her 6 month checkup. She didn't even know she was getting a cavity fixed.`,
        name: 'Sabrina S.',
        rating: '3',
    },
    {
        review: `Staff and doctor are all Angels. My son received expert care and was completely comfortable the entire time.`,
        name: 'Sherriza N.',
        rating: '4',
    },
];

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 00px;

    .blob {
        position: absolute;
        width: 520px;
        align-self: center;
        top: 247px;
        z-index: -1;
    }
    .nextSlide {
        opacity: 0.8;
        transform: translateX(100%);
        transition: 0.2s ease-in;
    }
    .activeSlide {
        transition: 0.2s ease-in;
        transform: translateX(00%);
        background: #fcfcfc;
        border: 1px solid #7bd2f7;
    }
    .prevSlide {
        margin-right: 132px;
        opacity: 0.8;
        transition: 0.2s ease-in;
        transform: translateX(-100%);
    }
    margin: 0 auto;
    @media ${device.laptop} {
        padding: 0px 0px 0px;
        .blob {
            position: absolute;
            width: 1060px;
            align-self: center;
            top: 127px;
        }
        .prevSlide {
            margin-right: 132px;
            opacity: 0.8;
        }
        .nextSlide {
            opacity: 0.8;
            margin-left: 132px;
        }
    }
`;

const Title = styled.div`
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;

    p {
        color: ${colors.blue4};
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }
`;

const Reviews = styled.div`
    .center-section {
        display: flex;
        overflow-x: scroll;
        position: relative;
        height: 500px;
        margin: 0 auto;
        justify-content: center;
    }
    @media ${device.laptop} {
        .center-section {
            margin: 0 auto;
            justify-content: center;
            overflow: hidden;
            height: 600px;
        }
    }
`;

const Card = styled.div`
    min-width: 312px;

    max-width: 312px;
    position: absolute;
    top: 0;
    margin: 0px 8px;
    height: 453px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0px 32px;
    border-radius: 15px;
    .image {
        border-radius: 100%;
        height: 88px;
        width: 88px;
        img {
            height: 88px;
            width: 88px;
        }
    }
    @media ${device.laptop} {
        justify-content: center;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 140px;
    bottom: 0px;

    position: absolute;
    margin: 0 auto;
    div {
        cursor: pointer;
    }
    /* div {
        background: none;
        border: none;
    } */
    @media ${device.laptop} {
        div:first-of-type {
            right: 500px;
            bottom: 340px;
            position: relative;
        }
        div:last-of-type {
            left: 500px;
            bottom: 340px;
            position: relative;
        }
    }
`;

const DotContainer = styled.div`
    display: none;
    @media ${device.laptop} {
        justify-content: space-between;
        width: 130px;
        display: flex;
        bottom: 64px;
        position: absolute;
        margin: 0 auto;
        div {
            cursor: pointer;
            height: 16px;
            width: 16px;
            border: 2px solid #7b878f;
            border-radius: 100%;
        }
        .filled {
            border: 2px solid #7b878f;
            background: grey;
        }
    }
`;

export const PatientReviews = () => {
    const reviewRef = useRef(null);
    const [index, setIndex] = useState(0);

    const [slide] = useState(reviews);

    useEffect(() => {
        if (index < 0) {
            setIndex(slide.length - 1);
        }
        if (index > slide.length - 1) {
            setIndex(0);
        }
    }, [index, slide]);

    useEffect(() => {
        const slider = setInterval(() => {
            setIndex(index + 1);
        }, 5000);
        return () => {
            clearInterval(slider);
        };
    }, [index, slide]);

    const images = useStaticQuery(graphql`
        {
            allFile(filter: { relativeDirectory: { eq: "reviews" } }) {
                edges {
                    node {
                        id
                        childrenImageSharp {
                            gatsbyImageData(placeholder: NONE, quality: 100)
                        }
                        name
                    }
                }
            }
        }
    `);
    const { allFile } = images;
    return (
        <Container>
            <Blob className="blob" />
            <Title>
                <h2>What Patients say</h2>
                <p>5 Star Reviews</p>
            </Title>

            <Reviews>
                <div className="center-section">
                    {reviews.map((reviewItem, i) => {
                        const { review, name } = reviewItem;
                        let position = 'nextSlide';
                        if (index === i) {
                            position = 'activeSlide';
                        }
                        // this checks for the previous and the last slide in the second condition
                        if (i === index - 1 || (index === 0 && i === reviews.length - 1)) {
                            position = 'prevSlide';
                        }
                        return (
                            <Card className={position} ref={reviewRef} key={name}>
                                <GatsbyImage
                                    className="image"
                                    alt={allFile.edges[i].node.name}
                                    image={
                                        allFile.edges[i].node.childrenImageSharp[0].gatsbyImageData
                                    }
                                />
                                <p>{review}</p>
                                <p>{name}</p>
                                <div>
                                    <Star />
                                    <Star />
                                    <Star />
                                    <Star />
                                    <Star />
                                </div>
                            </Card>
                        );
                    })}
                    <ButtonContainer>
                        <div onClick={() => setIndex(index - 1)}>
                            <LeftArrow />
                        </div>
                        <div onClick={() => setIndex(index + 1)}>
                            <RightArrow />
                        </div>
                    </ButtonContainer>
                    <DotContainer>
                        {reviews.map((review, i) => {
                            return (
                                <div
                                    onClick={() => setIndex(i)}
                                    className={`${index === i && 'filled'}`}
                                    key={review.name}
                                ></div>
                            );
                        })}
                    </DotContainer>
                </div>
            </Reviews>
        </Container>
    );
};
