import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { ReactComponent as Check } from '../images/svgs/check.svg';

import { SecondaryButton } from './layout/StyledComponents';
import { DentalPlans } from './Dental-Plans';
import { Link } from 'gatsby';

const Container = styled.div`
    margin-top: 72px;
    height: 1075px;
    position: relative;
    .clicked {
        background: grey;
    }
    .container {
        display: flex;
        width: 400px;
        justify-content: space-evenly;
        button {
            border-radius: 100%;
            background: none;
            border: 1px solid grey;
            width: 20px;
            height: 20px;
        }
    }
    h2 {
        text-align: left;
        color: white;
        padding-top: 82px;
    }
    @media ${device.tablet} {
        height: 650px;
    }
    @media ${device.laptop} {
        height: 100%;
        width: 100vw;
        h2 {
            text-align: left;
            color: ${colors.gray5};
            padding-top: 82px;
        }
    }
`;

const BG = styled.div`
    position: absolute;
    z-index: -1;
    .shadow {
        position: absolute;
        width: 100vw;
        z-index: -1;
        height: 1075px;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
    }
    img {
        z-index: -2;
        height: 1075px;
    }
    @media ${device.tablet} {
        .shadow,
        img {
            height: 650px;
        }
    }
    @media ${device.laptop} {
        .shadow,
        img {
            height: 100%;
            width: 100vw;
        }
        .bg-image {
            max-height: 700px;
            width: 100vw;
        }
        .shadow {
            display: none;
        }
    }
`;

const Content = styled.div`
    padding: 0px 16px;
    @media ${device.tablet} {
        display: flex;
        align-items: end;
    }
    @media ${device.laptop} {
        display: flex;
        justify-content: space-around;
    }
`;

const List = styled.div`
    ul {
        color: white;
        list-style: none;
        li {
            color: white;
        }
        svg {
            fill: #7bd2f7;
            margin: 16px 16px 0 0;
        }
    }
    @media ${device.laptop} {
        ul {
            color: ${colors.gray5};
            li {
                color: ${colors.gray5};
            }
            svg {
                fill: ${colors.blue4};
                margin: 16px 16px 0 0;
            }
        }
    }
`;

const Panel = styled.div`
    width: 343px;
    height: 444px;
    background: #fcfcfc;
    border-radius: 15px;
    padding: 20px 42px;
    margin-top: 32px;
    position: relative;
    ul {
        list-style: none;
        li {
            margin-bottom: 24px;
        }
    }
    p:first-of-type {
        text-align: center;
        margin-bottom: 60px;
        font-weight: 600;
        font-size: 20px;
        line-height: 144%;
        text-transform: capitalize;
        color: ${colors.gray5};
    }
    p:first-of-type:after {
        content: '';
        display: block;
        margin: 0 auto;
        position: relative;
        top: 16px;
        width: 229.24px;
        height: 0px;
        border: 1px solid ${colors.gray2};
    }
    button {
        padding: 16px 0px;
        color: white;
        border-radius: 0px 0px 15px 15px;
        bottom: 0px;
        border: none;
        width: inherit;
        position: absolute;
        left: 0;
        background: ${colors.blue3};
        display: flex;
        justify-content: center;
        align-items: center;
        b {
            margin: 0 16px;
        }
        svg {
            fill: white;
        }
        svg:first-of-type {
            padding-right: 0px;
            width: 18px;
        }

        svg:last-of-type {
            width: 23px;

            transform: rotate(180deg);
        }
    }
    @media ${device.laptop} {
        width: 305px;
        height: 477px;
    }
`;

export const NoInsurance = () => {
    return (
        <Container>
            <BG>
                <div className="shadow"></div>
                <StaticImage
                    className="bg-image"
                    src="../images/children-wall.jpeg"
                    quality={100}
                    alt="children hiding behind wall"
                    placeholder="none"
                />
            </BG>
            <Content>
                <div>
                    <h2>
                        No Insurance
                        <br /> No worries
                    </h2>

                    <List>
                        <ul>
                            <li>
                                <Check />
                                No Hidden Fees
                            </li>
                            <li>
                                <Check />
                                No Deductibles
                            </li>
                            <li>
                                <Check />
                                No Claim Forms
                            </li>
                            <li>
                                <Check />
                                No Waiting Period
                            </li>
                            <li>
                                <Check />
                                No Annual Maximums
                            </li>
                            <li>
                                <Check />
                                No Exculusions
                            </li>
                            <li>
                                <Check />
                                No Credit Verifications
                            </li>
                        </ul>
                        <Link to="/resources/financial-info/">
                            <SecondaryButton color="blue">Explore more</SecondaryButton>
                        </Link>
                    </List>
                </div>
                <DentalPlans />
            </Content>
        </Container>
    );
};
