import React from 'react';
import { device } from './layout/GlobalStyles';
import styled from '@emotion/styled';

const StyledPictureMain = styled.div`
    margin-top: 32px;
    .underlayer {
        opacity: 0.7;
        filter: blur(41px);

        position: absolute;
        top: 2em;
        left: 2em;
        width: 90%;
        height: 176px;
    }
    .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        width: 95vw;
        height: 226px;
        img {
            border-radius: 15px;
        }
    }
    @media ${device.tablet} {
        .underlayer {
        }
        .image-container {
            margin: 0 auto;
            width: 700px;
            img {
                width: 700px;
            }
        }
    }
    @media ${device.laptop} {
        .underlayer {
        }
        .image-container {
            margin: 0 auto;
            width: 800px;

            img {
                width: 800px;
            }
        }
    }
    @media ${device.laptopL} {
        .image-container {
            height: 502px;
            width: 1080px;
            margin: 0 auto;
            img {
                width: 1080px;
            }
            .underlayer {
                position: absolute;
                top: 3.5em;
                width: 90%;
                height: 440px;
            }
        }
    }
    /* @media ${device.desktop} {
        .image-container {
            height: 486px;
            width: 1300px;
            img {
                width: 1300px;
            }
            .underlayer {
                position: absolute;
                top: 2.5em;
                left: 7em;
                width: 90%;
                height: 480px;
            }
        }
    } */
`;

interface Props {
    children: React.ReactNode;
}

export const PictureContainerLarge: React.FC<Props> = ({ children }) => {
    return (
        <StyledPictureMain>
            <div className="image-container">{children}</div>
        </StyledPictureMain>
    );
};
