import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import { PictureContainerLarge } from './Picture-Container-Large';
import { SecondaryButton } from './layout/StyledComponents';
import { ReactComponent as BlobOne } from '../images/svgs/blob-1.svg';
import { ReactComponent as BlobTwo } from '../images/svgs/blob-2.svg';
import { Link } from 'gatsby';

const Container = styled.div`
    a {
        font-weight: 700;
        color: ${colors.blue3};
    }
`;

const Image = styled.div`
    margin-top: 150px;
    svg {
        display: none;
    }
    @media ${device.tablet} {
        position: relative;

        margin-top: 128px;
    }
    @media ${device.laptop} {
        svg {
            display: inline-block;
            position: absolute;
        }
        svg:first-of-type {
            z-index: -1;
            left: -100px;
            top: -100px;
        }
        svg:last-of-type {
            right: -150px;
            bottom: 50px;
        }
    }
    @media ${device.laptopL} {
        margin-top: 35vh;
    }
`;

const TextBox = styled.div`
    p {
        span {
            color: ${colors.blue3};
        }
    }
    @media ${device.tablet} {
        position: relative;
        button {
            margin-top: 36px;
            position: absolute;
            right: 0em;
        }
    }
    @media ${device.laptop} {
        max-width: 900px;
        margin: 0 auto;
    }
`;

export const WhoWeAre = () => {
    return (
        <Container className="container">
            <Image>
                <BlobOne />
                <BlobTwo />
                <PictureContainerLarge>
                    <StaticImage
                        className="underlayer"
                        src="../images/team-smiling.jpeg"
                        alt="team smiling in a group"
                        quality={100}
                    />
                    <StaticImage
                        src="../images/team-smiling.jpeg"
                        alt="team smiling in a group"
                        quality={100}
                    />
                </PictureContainerLarge>
            </Image>
            <TextBox>
                <h2 className="center-text">
                    WHO WE{' '}
                    <span>
                        ARE<div></div>
                    </span>
                </h2>
                <p>
                    At{' '}
                    <Link to="/">
                        <span>Clermont Kids Dentistry, </span>
                    </Link>
                    we know that dental health is important to your child’s overall health and
                    appearance. Our team manages all of your child’s dental needs including:
                    Preventative care, dental education, nutritional counseling, screening for sleep
                    apnea and oral cancer, sedation dentistry with our Pediatric Anesthesiologist{' '}
                    <a
                        target="__blank"
                        rel="noopener noreferrer"
                        href="https://pediatricsedation.com/"
                    >
                        (PDAA)
                    </a>
                    , dental emergencies, lip and tongue tie assessment and treatment, tooth-colored
                    fillings, tooth-colored crowns, baby root canals, patients with special needs,
                    early and comprehensive orthodontics. We serve a wide range of communities from
                    Daytona Beach, Tampa, South and North Florida as well as the surrounding
                    communities of Minneola, Groveland, Eustis, Clermont, Ocoee, Winter Garden,
                    Tavares, Apopka, Doctor Phillips, Windermere, Mount Dora, Orlando, The Villages,
                    Davenport, and more.
                </p>
                <Link to="/about-us/">
                    <SecondaryButton color="blue">get to know more about us</SecondaryButton>
                </Link>
            </TextBox>
        </Container>
    );
};
